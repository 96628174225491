@tailwind base;
@tailwind components;
@tailwind utilities;

.otp-input {
  @apply m-2 w-[40px] md:w-[50px] lg:w-[50px] text-[25px] md:text-[35px] lg:text-[35px] p-[5px] pl-[8px] pr-[8px] border-[2px] border-[#fd8b98] outline-none outline-0 rounded-md text-center focus:border-[#e61c34]
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app {
  height: calc(100vh - 110px);
}

.PhoneInputInput {
  outline: none;
  outline-width: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-loader {
  border: 2px solid #e61c34; /* Light grey */
  border-top: 2px solid #ffffff; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 500ms linear infinite;
}

.loader {
  border: 2px solid #e61c34; /* Light grey */
  border-top: 2px solid #ffffff; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 500ms linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Customize the label (the checkbox-container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #e61c346e;
  border-radius: 99%;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #e61c346e;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #e61c34;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 2px;
  margin: auto;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.referrer-wrapper {
  animation: scale 350ms;
}

.grecaptcha-badge {
  z-index: 999;
  margin-bottom: 100px;
}

@media (max-height: 1300px) {
  .backup-account-page {
    margin-top: 100px;
  }
}


@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}